import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: '18px 0',
  },
  item: {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  article: {
    padding: 18,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  }
});

class BlogRollPlain extends React.Component {
  render() {
    const { data, classes } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className={classes.root}>
        <Grid container spacing={0}>
          {posts && (posts
              .map(({ node: post }) => (
                <Grid key={post.id} item xs={12} md={6} className={classes.item}>
                  <article className={classes.article}>
                    <Link to={post.fields.slug}>
                      <Typography variant="h4" color="primary" gutterBottom>
                        {post.frontmatter.title}
                      </Typography>
                    </Link>
                    <p>{post.frontmatter.description}</p>
                    <Button color="secondary" className={classes.button} component={Link} to={post.fields.slug}>
                      Keep Reading →
                    </Button>
                  </article>
                </Grid>
              )))}
        </Grid>
      </div>
    );
  }
}

BlogRollPlain.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

const BlogRoll = withStyles(styles)(BlogRollPlain);

export default () => (
  <StaticQuery
    query={graphql`
    query BlogRollQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] },
        filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              description
              templateKey
              date(formatString: "MMMM DD, YYYY")
            }
          }
        }
      }
    }
    `}
    render={(data, count) => (
      <BlogRoll data={data} count={count} />
    )}
  />
)
