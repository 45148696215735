import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import Header from '../../components/Header'

export default class BlogIndexPage extends React.Component {
  render() {
  return (
    <Layout
      header={ <Header title="Latest Stories" /> }
      content={ <BlogRoll /> }
    />
    )
  }
}
